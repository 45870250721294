import React from 'react';
import { Text, XStack, Stack, useTheme } from 'tamagui';
import { Link } from 'solito/link';
import { usePathname } from 'solito/navigation';

const Breadcrumb = ({
  skipSegments = [],
  specificPath = null,
  customPaths = [],
  lastItemLabel = null,
}) => {
  const theme = useTheme();
  const pathname = specificPath || usePathname();
  const isRoot = pathname === '/';

  // TODO - hacky fix to replace breaking bookmarks, fix
  if (!pathname) return null;

  let breadcrumbItems = [];

  if (customPaths.length > 0) {
    breadcrumbItems = customPaths.map(({ path, label }) => ({
      label,
      href: path,
    }));
  } else {
    const segments = pathname.split('/').filter((segment) => segment !== '');
    breadcrumbItems = segments
      .filter((segment) => !skipSegments.includes(segment))
      .map((segment, index, filteredSegments) => {
        const href = `/${filteredSegments.slice(0, index + 1).join('/')}`;
        const label = segment
          .split('-')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
        return { label, href };
      });
  }

  return (
    <XStack
      justifyContent="center"
      $md={{ justifyContent: 'flex-start' }}
      alignItems="center"
      ml={8}
      mb={isRoot ? 0 : 25}
      mt={0}
    >
      {isRoot ? null : (
        <Link href="/">
          <Stack hoverStyle={{ opacity: 0.5 }}>
            <Text
              color={theme.mainColor.get()}
              fontWeight="bold"
              textDecorationLine="underline"
              fontSize={10}
              $sm={{ fontSize: 14 }}
              $md={{ fontSize: 18 }}
            >
              Home
            </Text>
          </Stack>
        </Link>
      )}
      {breadcrumbItems.map((item, index) => (
        <XStack key={item.href} alignItems="center">
          <XStack px={10}>
            <Text
              color={theme.mainColor.get()}
              fontSize={10}
              $sm={{ fontSize: 10 }}
              $md={{ fontSize: 18 }}
            >
              /
            </Text>
          </XStack>
          {index === breadcrumbItems.length - 1 ? (
            <Text
              color={theme.mainColor.get()}
              fontSize={10}
              $sm={{ fontSize: 14 }}
              $md={{ fontSize: 18 }}
            >
              {lastItemLabel || item.label}
            </Text>
          ) : (
            <Link href={item.href}>
              <Stack hoverStyle={{ opacity: 0.5 }}>
                <Text
                  color={theme.mainColor.get()}
                  fontWeight="bold"
                  textDecorationLine="underline"
                  fontSize={10}
                  $sm={{ fontSize: 14 }}
                  $md={{ fontSize: 18 }}
                >
                  {item.label}
                </Text>
              </Stack>
            </Link>
          )}
        </XStack>
      ))}
    </XStack>
  );
};

export default Breadcrumb;
