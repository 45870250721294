import API from 'utils/API';
import { store } from 'reducers/store';
import { settingsSave } from 'reducers/slices/settings';
import { HSKs } from 'config/constants';
const api = new API();
export const getSkillLevels = async lang_code => {
  let response = await api.get('skillLevelsAPI', {
    query: {
      lang_code: lang_code
    }
  });
  return response;
};
export const updateHSKs = async () => {
  let hsk = {
    hsk1: true,
    hsk2: true,
    hsk3: true,
    hsk4: true,
    hsk5: true,
    hsk6: true,
    hsk7: true,
    hsk8: true,
    hsk9: true,
    hsk9plus: true
  };
  await store.dispatch(settingsSave(hsk));
};
export const levelMapperReturn = skills => {
  let _selectedLevels = [];
  for (let levelItem in skills) {
    if (skills[levelItem] === true) {
      let levelVal = HSKs.filter(item => item.value === levelItem);
      let levelValObj = levelVal[0];
      _selectedLevels.push(levelValObj);
    }
  }
  return _selectedLevels;
};
export const getLevels = skills => {
  if (Array.isArray(skills)) {
    const hsks = skills.map(obj => {
      return obj.level;
    });
    return hsks;
  }
  return [];
};

/*export const getLevels = (skills) => {
  let _selectedHSKs = levelMapperReturn(skills);
  let hsks = _selectedHSKs.map((obj) => {
    return obj.id;
  });
  return hsks;
};
*/